import moment from 'moment'
import {getCheckIns} from "./shifts";
export function getClientImageViewLink(client){
    if(!client || !client.companyImage || client.companyImage === ''){
        return null
    }
    return client.companyImageS3
}

export function getUserImageViewLink(user){
    if(!user || !user.profileImage || user.profileImage === ''){
        return null
    }
    return user.profileImageS3
}

export function hasSingingImage(checkInsOuts){
    return checkInsOuts && checkInsOuts.signingImage && (checkInsOuts.signingImage !== '' || checkInsOuts.signingImage !== ' ')
}

export function getSigningImageOfShift(shift){
    const { checkInsOut } = getCheckIns(shift)
    if(!hasSingingImage(checkInsOut)){
        return null
    }
    return checkInsOut.signingImageS3
}

export function getCheckInOutsImageOfShift(image){
    if(!image || !image.imageViewLink || image.imageViewLink === ''){
        return null
    }
    return image.imageViewLinkS3
}

const EMPTY_TIME_CHARACTER = "..";

export function formatDateStringForDisplay(dateString, format = 'DD-MM-YYYY'){
    if(!dateString) return EMPTY_TIME_CHARACTER
    const convertedDate = moment(dateString)
    return convertedDate.isValid() ? convertedDate.format(format) : EMPTY_TIME_CHARACTER;
}

export function extractDateTimeDetailsForDisplay(dateTimeString) {
    const dateTime = moment(dateTimeString);
    if (!dateTime.isValid()) {
        return { date: EMPTY_TIME_CHARACTER, hours: EMPTY_TIME_CHARACTER, minutes: EMPTY_TIME_CHARACTER }
    }
    const date = dateTime.format("DD-MM-YYYY");
    const hours =  dateTime.format("HH");
    const minutes= dateTime.format("mm");
    return { date, hours, minutes };
}