export const getCheckIns = (shift) => {
    if (!shift) {
        return {}
    }
    const checkInsOut = shift.jobApplicationBase?.checkInsOuts?.[0] || {}

    return {checkInsOut, checkInOne: checkInsOut?.checkInOne, checkInTwo: checkInsOut?.checkInTwo, checkOut: checkInsOut?.checkOut}
};

export const getJobOfShift = (shift) => {
    return shift?.jobApplicationBase?.job
}

export const getProjectOfShift = (shift) => {
    return shift?.jobApplicationBase?.job?.project
}

export const getClientOfShift = (shift) => {
    return shift?.jobApplicationBase?.job?.project.client?.[0]
}

// job_supplier or job_owner
export function isShiftOwner(shift, hoursOwnerType) {
    const job = getJobOfShift(shift)
    if(hoursOwnerType === "job_owner"){
        return job?.isOwner
    }

    return shift?.isOwner
}
